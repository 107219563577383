import { Injectable } from '@angular/core';
import { ActionButton, ButtonType, LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';
import { PreviousRouteService } from '@core/services/previous-route.service';
import { TranslateService } from '@ngx-translate/core';
import { EntityConfig } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { FormConfig, FormHelper } from '@core/enums/form-config.enum';
import { DestructureHelper } from '@core/utils/destructure.helper';
import { IEntityBase } from '@core/interfaces/entity-base';

@Injectable({
  providedIn: 'root',
})
export class FormConfigService<Model extends IEntityBase> extends DestroyBase {
  constructor(
    private layoutConfigService: LayoutConfigService,
    private previousRouteService: PreviousRouteService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public generateDefaultBackUrl(formConfig: FormConfig, entityConfig: EntityConfig): string | string[] {
    const typeOfPage = FormHelper.isAdd(formConfig) ? AppRoutes.add : AppRoutes.edit;

    return this.previousRouteService.getDefaultBackUrl(typeOfPage, entityConfig);
  }

  public defaultActionButtons(formConfig: FormConfig, entityConfig: EntityConfig): ActionButton[] {
    return [this.defaultCancelButton(formConfig, entityConfig), this.defaultSubmitButton(formConfig, entityConfig)];
  }

  public defaultCancelButton(formConfig: FormConfig, entityConfig: EntityConfig): ActionButton {
    return {
      type: FormHelper.isEdit(formConfig) ? ButtonType.DISCARD : ButtonType.CANCEL,
      label: this.translateService.stream(`dxp.${entityConfig.name}.button.cancel`),
      styleClass: 'secondary',
      icon: 'fa fa-ban',
      debounceTime: 0,
      route: this.generateDefaultBackUrl(formConfig, entityConfig),
    };
  }

  public defaultSubmitButton(formConfig: FormConfig, entityConfig: EntityConfig): ActionButton {
    return {
      type: ButtonType.SUBMIT,
      label: this.translateService.stream(`dxp.${entityConfig.name}.button.submit`),
      icon: formConfig.submitIcon$,
      debounceTime: 500,
    };
  }

  public overwriteCancelRoute(buttons: ActionButton[], route: string | string[]): ActionButton {
    return DestructureHelper.findAndOverwriteAttributes<ActionButton, ButtonType>(
      buttons,
      [ButtonType.CANCEL, ButtonType.DISCARD],
      { route },
    );
  }

  public overwriteSubmitCallback(buttons: ActionButton[], callback: () => void): ActionButton {
    return DestructureHelper.findAndOverwriteAttributes<ActionButton, ButtonType>(
      buttons,
      [ButtonType.SUBMIT, ButtonType.SAVE],
      { callback },
    );
  }

  public overwriteDefaultFormButtons(
    buttons: ActionButton[],
    route: string | string[],
    callback: () => void,
  ): ActionButton[] {
    return [this.overwriteCancelRoute(buttons, route), this.overwriteSubmitCallback(buttons, callback)];
  }

  public generateDefaultSubtitle(
    formConfig: FormConfig,
    entityConfig: EntityConfig,
    additionalParts?: (string | Observable<string>)[],
  ): string | Observable<string> {
    const action = FormHelper.isEdit(formConfig) ? 'edit' : 'new';
    const entityAction = this.translateService.stream(`dxp.${entityConfig.name}.entity_name`).pipe(
      switchMap((entity: string) => {
        return this.translateService.stream(`dxp.button.${action}-entity`, { entity: entity.toLowerCase() });
      }),
    );

    let subtitleKeys: (string | Observable<string>)[];

    if (FormHelper.isEdit(formConfig)) {
      subtitleKeys = [this.translateService.stream('dxp.subtitle.details'), entityAction];
    } else {
      subtitleKeys = [entityAction];
    }

    if (additionalParts) {
      subtitleKeys = [...subtitleKeys, ...additionalParts];
    }

    return this.layoutConfigService.generateSubtitle(subtitleKeys);
  }

  public generateDefaultConfig(
    item: Model,
    entityConfig: EntityConfig,
    pageConfig: Partial<LayoutConfig>,
    formConfig: FormConfig,
  ): Observable<LayoutConfig> {
    return this.layoutConfigService.generateDefaultConfig(entityConfig).pipe(
      map((defaultConfig: LayoutConfig) => {
        return {
          ...defaultConfig,
          subtitle: this.generateDefaultSubtitle(formConfig, entityConfig),
          backUrl: this.generateDefaultBackUrl(formConfig, entityConfig),
          actionButtons: this.defaultActionButtons(formConfig, entityConfig),
          ...pageConfig,
        } as LayoutConfig;
      }),
      takeUntil(this.destroy$),
    );
  }
}
